<template>
  <div class="code">
    <img :src="require('../code.png')" />
    <div class="mask loading">登录中...</div>
    <div class="mask fail">扫码失败</div>
  </div>
</template>

<script setup>

import { onMounted } from 'vue'

// 获取地址参数
function getQuery(name) {
  let reg = new RegExp('(^|&)' + name + "=([^&]*)", "i")
  let r = decodeURI(window.location.search.substr(1)).match(reg)
  return r ? r[2] : null
}

// 发送请求
function sendXHR(url, method, Authorization = false) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest()
    const origin = location.origin
    xhr.open(method, origin + url)

    Authorization && xhr.setRequestHeader("Authorization", "Basic dGVhY2hlcjp0ZWFjaGVy")
    xhr.setRequestHeader("TENANT-ID", "2")
    xhr.setRequestHeader("CLIENT-ID", "teacher")

    xhr.send()
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const res = JSON.parse(xhr.responseText)
        resolve(res)
      }
    }
  })

}

// 获取是否绑定
function getBind(code) {
  const url = `/napi/admin/wxlogin/auth?code=${code}`
  sendXHR(url, "GET").then(res => {
    if (res.msg === 'binding') {
      getToken(res.data)
    } else {
      const failDom = document.querySelector('.fail')
      const loadDom = document.querySelector('.loading')
      failDom.style.display = 'block'
      loadDom.style.display = 'none'

      const data = {
        success: 'False',
        token: JSON.stringify({}),
        msg: '登录失败'
      }
      sendBridge(data)
    }
  })
}

// 获取token
function getToken(openId) {
  const mobil = encodeURIComponent(`WX@@@${openId}`)
  const url = `/napi/auth/mobile/token/social/?mobile=${mobil}&grant_type=mobile`

  sendXHR(url, "POST", true).then(res => {
    const data = {
      success: 'True',
      token: JSON.stringify(res),
      msg: '登录成功'
    }
    sendBridge(data)
  })
}

// 与 C# 交互
/** 方法名 weixinLogin
  success string
  token string
  msg string
  */
function sendBridge(data) {
  /* eslint-disable-next-line no-undef */
  (typeof jsCsharp !== 'undefined') ? jsCsharp.weixinLogin(data.success, data.token, data.msg) : alert('未成功绑定jsCsharp对象')
}

onMounted(() => {
  const code = getQuery('code')
  if (typeof CefSharp !== 'undefined') {
    /* eslint-disable-next-line no-undef */
    CefSharp.BindObjectAsync("jsCsharp").then(() => {
      getBind(code)
    })
  } else {
    getBind(code)
  }
})
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
  }

  html,
  body {
    width: 200px;
    height: 200px;
    overflow: hidden;
  }

  .code {
    width: 200px;
    height: 200px;
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .mask {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 75px 0;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    background-color: #000000;
    opacity: 0.8;
    color: #fff;
    font-size: 30px;
  }

  .fail {
    display: none;
  }
</style>
      