<template>
  <div id="login-code"></div>
</template>

<script setup>

import { onMounted } from 'vue'

onMounted(() => {
  /* eslint-disable-next-line no-undef */
  new WxLogin({
    self_redirect: true,
    id: "login-code",
    appid: "wxfcbccbc8485f3632",
    scope: "snsapi_login",
    response_type: 'code',
    redirect_uri: encodeURIComponent("https://wxproxy.zhixuehuijiao.cn"),
    href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLndycF9jb2RlIHt3aWR0aDoyMDBweDt9Ci5pbXBvd2VyQm94IC5xcmNvZGUge3dpZHRoOjIwMHB4O2JvcmRlcjogbm9uZTttYXJnaW4tdG9wOiAwO30KLmltcG93ZXJCb3ggLnN0YXR1c19icm93c2VyIHtkaXNwbGF5OiBub25lO30="
  });
})
</script>

<style lang="scss" scoped>
  * {
    margin: 0;
    padding: 0;
  }
  html, body, #login-code, iframe {
    width: 200px;
    height: 200px;
    overflow: hidden;
  }
  #login-code {
    margin: 0 auto;
  }
</style>
      